import React from "react";
import SingleJobPage from "../../components/SingleJobPage";
import withLayout from "../../layout";
import SEO from "../../components/SEO";
import {injectIntl} from "react-intl";

/**
 * note that the key we defined in role is needed for building the SingleJobPage
 * component with the right translation strings and media
 * @returns { SingleJobPage }
 * @constructor
 */

const SingleJobPageWrapper = ({intl}) => {
    const IntlTextObj = {
        role: "fullstack.senior.title",
        roleValue: "Full-stack SR",
        skills: {
            experience_title: "fullstack.experience_title",
            experience: "fullstack.senior.experience",
            employment_title: "fullstack.employment_title",
            employment: "fullstack.employment",
            placeofwork_title: "fullstack.placeofwork_title",
            placeofwork: "fullstack.placeofwork",
            title_left: "fullstack.title_left",
            text_left: "fullstack.text_left",
            title_right: "fullstack.title_right",
            text_right: "fullstack.text_right",
            text_center: "fullstack.text_center",
        },
        hard_skills_title: "fullstack.hard_skills_title",
        hard_skills: ["fullstack.senior.hard_skill_1", "fullstack.senior.hard_skill_2", "fullstack.senior.hard_skill_3", "fullstack.senior.hard_skill_4", "fullstack.senior.hard_skill_5", "fullstack.senior.hard_skill_6", "fullstack.senior.hard_skill_7", "fullstack.senior.hard_skill_8", "fullstack.senior.hard_skill_9","fullstack.senior.hard_skill_10"],
        soft_skills_title: "fullstack.soft_skills_title",
        soft_skills: ["fullstack.senior.soft_skills_1", "fullstack.senior.soft_skills_2", "fullstack.senior.soft_skills_3", "fullstack.senior.soft_skills_4", "fullstack.senior.soft_skills_5", "fullstack.senior.soft_skills_6"],
        adventure_title_thin: "fullstack.adventure_title_thin",
        adventure_title: "fullstack.adventure_title",
        benefits_title: "fullstack.benefits_title",
        benefits: ["fullstack.benefit_1", "fullstack.benefit_2", "fullstack.benefit_3", "fullstack.benefit_4", "fullstack.benefit_5"],
        offer_title: "fullstack.offer_title",
        offer: ["fullstack.offer_1", "fullstack.offer_2", "fullstack.offer_3", "fullstack.offer_4", "fullstack.offer_5"],
    };
    const keywords = intl.formatMessage({id: 'fullstack.meta_keywords'}).split(',') || [];
    return <>
        <SEO title={intl.formatMessage({id: 'fullstack.meta_title'})}
             description={intl.formatMessage({id: 'fullstack.meta_description'})} keywords={keywords}
             lang={intl.locale}/>
        <SingleJobPage IntlTextObj={IntlTextObj} intl={intl}/>
        {/* <SingleJobPage IntlTextObj /> */}
    </>
};

const customProps = {
    localeKey: "fullstack", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(SingleJobPageWrapper));
